import {
  MovementTransformed,
  TransactionMovement,
  TransactionType,
} from "@/types";
import { formatToInputDate } from "@/utils/date";
import { sub } from "date-fns";

export const DATE_RANGES = [7, 15, 30] as const;

const MAX_FILTER_RANGE = 90; // dias

export const STATEMENTS_FILTER_CATEGORIES = [
  "cartao",
  "Pix",
  "Payment",
  "TED",
  "P2P",
] as const;

// cria as labels para o filtro e transaction item
export const CATEGORIES_DICTIONARY: Record<TransactionType, string> = {
  Payment: "Boleto",
  cartao: "Cartão",
  P2P: "Hyperlocal",
  Pix: "PIX",
  TED: "Ted",
  Mobile: "Celular",
  Voucher: "Voucher",
  p2p_rec: "Transferência Hyperlocal",
  ted_spb_envio: "Transferência TED",
  doc_ted_fee: "Tarifa TED/DOC",
  ted_spb_envio_estorno: "Estorno TED",
  pix_debito: "Transferência Pix",
  pix_estorno_debito: "Estorno Pix",
};

export const RAW_TYPE_DICTIONARY: Record<TransactionType, string> = {
  Payment: "Payment",
  cartao: "Cartão",
  P2P: "P2P",
  Pix: "PIX",
  TED: "TED",
  Mobile: "Mobile",
  Voucher: "Voucher",
  p2p_rec: "P2P",
  ted_spb_envio: "TED",
  doc_ted_fee: "TED",
  ted_spb_envio_estorno: "TED",
  pix_debito: "PIX",
  pix_estorno_debito: "PIX",
};

export const MOVEMENT_DICTIONARY: Record<
  TransactionMovement,
  MovementTransformed
> = {
  IN: "Entrada",
  OUT: "Saída",
  CREDIT: "Entrada",
  DEBIT: "Saída",
  REVERSAL: "Entrada",
};

export const STATEMENTS_FILTER_MOVEMENTS = ["IN", "OUT"] as const;

export const TODAY_INPUT = formatToInputDate(new Date());

export const MIN_START_DATE = formatToInputDate(
  sub(new Date(), { days: MAX_FILTER_RANGE }),
);

export const TODAY = new Date();

export const STATEMENTS_FILTER_PARAMS = {
  from: "from",
  to: "to",
  search: "search",
  movement: "movement",
  category: "category",
};

export const COMMITMENT_FILTER_STATUS = [
  "SCHEDULED",
  "SCHEDULE_EXECUTED",
  "SCHEDULE_CANCELED",
] as const;

export const COMMITMENT_FILTER_CATEGORIES = ["TED/Digital", "Pix"] as const;

export const COMMITMENT_FILTER_PARAMS = {
  from: "from",
  to: "to",
  search: "search",
  status: "status",
};

export const COMMITMENT_STATUS_DICTIONARY: Record<
  (typeof COMMITMENT_FILTER_STATUS)[number],
  string
> = {
  SCHEDULED: "Pendente",
  SCHEDULE_CANCELED: "Cancelado",
  SCHEDULE_EXECUTED: "Efetuado",
};
