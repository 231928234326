import { Toggle } from "@/components";
import {
  CATEGORIES_DICTIONARY,
  DATE_RANGES,
  MIN_START_DATE,
  MOVEMENT_DICTIONARY,
  STATEMENTS_FILTER_CATEGORIES,
  STATEMENTS_FILTER_MOVEMENTS,
  STATEMENTS_FILTER_PARAMS,
  TODAY_INPUT,
} from "@/utils";
import { Button, Checkbox, TextField } from "@hyperlocal/vital2";
import { useSearchParams } from "react-router-dom";
import { tv } from "tailwind-variants";
import { useFilterHelper } from "./helper";

const style = tv({
  slots: {
    title: "text-neutral-darkest font-bold text-sm/[30px]",
  },
});

export const Filter = () => {
  const { title } = style();

  const [searchParams] = useSearchParams();

  const {
    getSelectedRange,
    handleDateInput,
    handleRangeSelect,
    handleSearch,
    clearFilter,
    handleMovement,
    handleCategories,
  } = useFilterHelper();

  const startDate = searchParams.get(STATEMENTS_FILTER_PARAMS.from) || "";
  const endDate = searchParams.get(STATEMENTS_FILTER_PARAMS.to) || "";
  const search = searchParams.get(STATEMENTS_FILTER_PARAMS.search) || "";
  const movementParam = searchParams.getAll(STATEMENTS_FILTER_PARAMS.movement);
  const categoryParam = searchParams.getAll(STATEMENTS_FILTER_PARAMS.category);
  const isFilteringWowStatements = searchParams.get("statements") === "WOW";

  const getEndDateRange = () => {
    const max = TODAY_INPUT;
    const min = startDate || MIN_START_DATE;
    return {
      max,
      min,
    };
  };

  const getStartDateRange = () => {
    const min = MIN_START_DATE;
    const max = endDate || TODAY_INPUT;
    return {
      min,
      max,
    };
  };

  return (
    <form className="flex min-h-[642px] w-full flex-col gap-inset-lg rounded-sm bg-neutral-white p-inset-lg">
      <TextField.Root className="mobile:hidden">
        <TextField.Input
          onChange={({ target }) => handleSearch(target.value)}
          value={search}
          placeholder="Buscar..."
        />
        <TextField.Slot name="SearchNormal" />
      </TextField.Root>
      <div>
        <h5 className={title()}>Filtrar cobranças</h5>
        <div className="mt-4 flex gap-2">
          {DATE_RANGES.map((daysNumber) => (
            <Toggle
              key={daysNumber}
              onPressedChange={() => handleRangeSelect(daysNumber)}
              pressed={getSelectedRange() === daysNumber}
            >
              {daysNumber} dias
            </Toggle>
          ))}
        </div>
      </div>
      <div>
        <h5 className={title()}>Período personalizado</h5>
        <div className="mt-4 flex gap-4">
          <div className="w-full">
            <TextField.Label htmlFor="from-date">De</TextField.Label>
            <TextField.Root>
              <TextField.Input
                onChange={({ target }) =>
                  handleDateInput({
                    inputParam: "from",
                    inputValue: target.value,
                  })
                }
                type="date"
                id="from-date"
                min={
                  isFilteringWowStatements ? undefined : getStartDateRange().min
                }
                max={
                  isFilteringWowStatements ? undefined : getStartDateRange().max
                }
                value={startDate}
              />
              <TextField.Slot name="GeralCalendar" className="cursor-pointer" />
            </TextField.Root>
          </div>
          <div className="w-full">
            <TextField.Label htmlFor="to-date">Até</TextField.Label>
            <TextField.Root>
              <TextField.Input
                type="date"
                id="to-date"
                onChange={({ target }) =>
                  handleDateInput({
                    inputParam: "to",
                    inputValue: target.value,
                  })
                }
                value={endDate}
                min={
                  isFilteringWowStatements ? undefined : getEndDateRange().min
                }
                max={
                  isFilteringWowStatements ? undefined : getEndDateRange().max
                }
              />
              <TextField.Slot name="GeralCalendar" className="cursor-pointer" />
            </TextField.Root>
          </div>
        </div>
      </div>

      {!isFilteringWowStatements && (
        <div>
          <h5 className={title()}>Tipos de lançamento</h5>
          <div className="mt-4 grid grid-cols-3 gap-4">
            {STATEMENTS_FILTER_MOVEMENTS.map((type) => (
              <div
                key={type}
                className="checkbox-statements-wildcard flex items-center"
              >
                <Checkbox.Slot
                  id={type}
                  onCheckedChange={() => handleMovement(type)}
                  checked={
                    !movementParam.length || movementParam.includes(type)
                  }
                />
                <Checkbox.Label htmlFor={type}>
                  {MOVEMENT_DICTIONARY[type]}
                </Checkbox.Label>
              </div>
            ))}
          </div>
        </div>
      )}

      {!isFilteringWowStatements && (
        <div>
          <h5 className={title()}>Categoria</h5>
          <div className="mt-4 grid grid-cols-3 gap-4">
            {STATEMENTS_FILTER_CATEGORIES.map((category) => (
              <div
                key={category}
                className="checkbox-statements-wildcard flex items-center"
              >
                <Checkbox.Slot
                  id={category}
                  onCheckedChange={() => handleCategories(category)}
                  checked={categoryParam.includes(category)}
                />
                <Checkbox.Label htmlFor={category}>
                  {CATEGORIES_DICTIONARY[category]}
                </Checkbox.Label>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="flex h-full items-end justify-center">
        <Button.Root variant="link" type="button" onClick={clearFilter}>
          Limpar filtro
        </Button.Root>
      </div>
    </form>
  );
};
