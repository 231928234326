import "./index.css";
import { queryClient } from "@/lib";
import { ParcelProps } from "@/types";
import { Provider } from "@hyperlocal/vital";
import { Toaster } from "@hyperlocal/vital2";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Routes } from "./routes";
import { GlobalStyles } from "./styles";
import { getVertical, themeProvider } from "./utils";
// @ts-ignore
import { Hooks } from "@hyperlocal/banking-utility";

const App = (props: ParcelProps) => {
  const theme = getVertical();
  const isMobile = Hooks.useMediaQuery("mobile");

  return (
    <Provider theme={theme}>
      <ThemeProvider theme={themeProvider}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV === "development" && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
            <GlobalStyles />
            <Routes {...props} />
            <Toaster position={isMobile ? "bottom-center" : "top-right"} />
          </QueryClientProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
