import { LoadingTransaction, Skeleton } from "@/components";

export const LoadingPayments = () => {
  return (
    <div className="flex h-full flex-col items-center gap-3 bg-neutral-white p-6 pt-8">
      <Skeleton className="h-4 w-full max-w-[50%]" />
      <Skeleton className="h-6 w-full max-w-[25%]" />
      <Skeleton className="h-4 w-full max-w-[70%]" />
      <Skeleton className="h-4 w-full max-w-[20%] self-start" />
      <LoadingTransaction />
    </div>
  );
};
