import {
  ComponentPropsWithoutRef,
  ComponentRef,
  ElementRef,
  forwardRef,
} from "react";
import * as svgs from "@/assets/svgs";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const LayoutRoot = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...rest }, ref) => (
  <div
    ref={ref}
    className={twMerge(
      "mx-auto w-full max-w-[1248px] flex-1 mobile:!overflow-hidden desktop:my-inset-lg desktop:px-16 desktop:pb-6",
      className,
    )}
    {...rest}
  />
));
LayoutRoot.displayName = "LayoutRoot";

const LayoutHeader = forwardRef<
  ComponentRef<"header">,
  ComponentPropsWithoutRef<"header">
>(({ className, ...rest }, ref) => (
  <header
    ref={ref}
    className={twMerge("mobile:!hidden", className)}
    {...rest}
  />
));
LayoutHeader.displayName = "LayoutHeader";

const LayoutColumn = forwardRef<
  ComponentRef<"section">,
  ComponentPropsWithoutRef<"section">
>(({ className, ...rest }, ref) => (
  <section ref={ref} className={twMerge("rounded-sm", className)} {...rest} />
));
LayoutColumn.displayName = "LayoutColumn";

interface LayoutLinkProps extends ComponentPropsWithoutRef<"h3"> {
  to?: string;
}

const LayoutLink = forwardRef<ElementRef<"h3">, LayoutLinkProps>(
  ({ className, children, to, ...rest }, ref) => {
    return (
      <h3
        className={twMerge(
          "my-6 flex items-center gap-2 text-lg/8 font-bold text-neutral-darkest mobile:!my-0 mobile:!mb-1 mobile:!px-inset-md mobile:!py-inset-md mobile:!pb-inset-md mobile:!text-sm/5 mobile:!shadow-lower",
          className,
        )}
        ref={ref}
        {...rest}
      >
        {to && (
          <Link to={to}>
            <svgs.ArrowLeft />
          </Link>
        )}
        {children}
      </h3>
    );
  },
);
LayoutLink.displayName = "LayoutLink";

const LayoutContent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...rest }, ref) => (
  <div
    ref={ref}
    className={twMerge(
      "relative grid grid-cols-2 gap-inset-lg overflow-hidden mobile:!grid-cols-1",
      className,
    )}
    {...rest}
  />
));
LayoutContent.displayName = "LayoutContent";

export const Layout = {
  Root: LayoutRoot,
  Header: LayoutHeader,
  Column: LayoutColumn,
  Title: LayoutLink,
  Content: LayoutContent,
};
