import {
  StatementListRequest,
  StatementListResponse,
  TransactionItem,
} from "@/types";
import { STATEMENTS_FILTER_PARAMS } from "@/utils";
import { QUERY_KEYS } from "@/utils/react-query-keys";
import { API, useAccountStore } from "@/utils/utility";
import {
  InfiniteData,
  useInfiniteQuery,
  UseQueryOptions,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { handleStatementsResponse } from "./use-get-statements.utils";

interface UseStatements extends Omit<StatementListRequest, "accountId"> {
  config?: UseQueryOptions<StatementListResponse>;
}

const getStatementParams = ({
  startDate,
  movement,
  endDate,
  type,
  _page,
  limit,
}: StatementListRequest) => {
  const params = new URLSearchParams();

  if (type.length) {
    params.set("type", type.join("-"));
  }

  if (movement) {
    params.set("movement", movement);
  }

  if (startDate && endDate) {
    params.set("startDate", startDate);
    params.set("endDate", endDate);
  }

  if (limit) params.set("limitPage", limit.toString());

  if (_page) params.set("page", _page.toString());

  return params;
};

export const getBankStatement = async (props: StatementListRequest) => {
  try {
    const params = getStatementParams(props);

    const { data } = await API.statements.get<StatementListResponse>(
      `/Extract`,
      {
        params,
      },
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const useStatementsList = ({
  movement,
  type,
  config,
  endDate,
  startDate,
  limit,
}: UseStatements) => {
  const [params] = useSearchParams();

  const search = params.get(STATEMENTS_FILTER_PARAMS.search);

  const { currentAccountId } = useAccountStore();

  return useSuspenseQuery({
    ...config,
    queryKey: QUERY_KEYS.getStatements({
      accountId: currentAccountId,
      startDate,
      endDate,
      movement,
      type,
      limit,
    }),
    queryFn: () =>
      getBankStatement({
        startDate,
        movement,
        endDate,
        type,
        limit,
      }),
    select: (data) => {
      const transformedResponse = handleStatementsResponse(data.Items, search);
      return transformedResponse;
    },
  });
};

export const useInfiniteStatements = ({
  startDate,
  movement,
  endDate,
  type,
}: UseStatements) => {
  const [params] = useSearchParams();

  const search = params.get(STATEMENTS_FILTER_PARAMS.search);

  const shouldUpdateDateQueryKey = !!endDate && !!startDate;

  const { currentAccountId } = useAccountStore();

  return useSuspenseInfiniteQuery({
    queryKey: QUERY_KEYS.getStatements({
      accountId: currentAccountId || "",
      movement,
      type,
      ...(shouldUpdateDateQueryKey && {
        endDate,
        startDate,
      }),
    }),
    initialPageParam: 0,
    select: (data: InfiniteData<StatementListResponse, number>) => {
      const result = data.pages.reduce<TransactionItem[]>(
        (prev, cur) => [...prev, ...cur.Items],
        [],
      );

      const transformedResponse = handleStatementsResponse(result, search);

      return {
        pages: transformedResponse,
        pageParams: data.pageParams,
      };
    },
    queryFn: ({ pageParam }) =>
      getBankStatement({
        startDate,
        movement,
        endDate,
        type,
        _page: pageParam,
      }),

    getNextPageParam: (page, _, lastPageParam) => {
      return page.Pagination.Next ? lastPageParam + 1 : undefined;
    },
  });
};

export const useStatements = ({
  startDate,
  movement,
  endDate,
  type,
}: UseStatements) => {
  const [params] = useSearchParams();

  const search = params.get(STATEMENTS_FILTER_PARAMS.search);

  const shouldUpdateDateQueryKey = !!endDate && !!startDate;

  const { currentAccountId } = useAccountStore();

  return useInfiniteQuery({
    queryKey: QUERY_KEYS.getStatements({
      accountId: currentAccountId || "",
      movement,
      type,
      ...(shouldUpdateDateQueryKey && {
        endDate,
        startDate,
      }),
    }),
    throwOnError: true,
    enabled: !!currentAccountId,
    initialPageParam: 0,
    select: (data: InfiniteData<StatementListResponse, number>) => {
      const result = data.pages.reduce<TransactionItem[]>(
        (prev, cur) => [...prev, ...cur.Items],
        [],
      );

      const transformedResponse = handleStatementsResponse(result, search);
      return {
        pages: transformedResponse,
        pageParams: data.pageParams,
      };
    },
    queryFn: ({ pageParam }) =>
      getBankStatement({
        startDate,
        movement,
        endDate,
        type,
        _page: pageParam,
      }),

    getNextPageParam: (page, _, lastPageParam) => {
      return page.Pagination.Next ? lastPageParam + 1 : undefined;
    },
  });
};
