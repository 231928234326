import {
  formatToInputDate,
  handleNormalizeDate,
  isValidDate,
  STATEMENTS_FILTER_CATEGORIES,
  STATEMENTS_FILTER_MOVEMENTS,
  STATEMENTS_FILTER_PARAMS,
  TODAY_INPUT,
} from "@/utils";
import { differenceInDays, sub } from "date-fns";
import { useSearchParams } from "react-router-dom";

type HandleDateInputProps = {
  inputValue: string;
  inputParam: Extract<keyof typeof STATEMENTS_FILTER_PARAMS, "from" | "to">;
};

type HandleMovementProps = (typeof STATEMENTS_FILTER_MOVEMENTS)[number];

export type HandleCategoriesProps =
  (typeof STATEMENTS_FILTER_CATEGORIES)[number];

const MIN_TYPE_PARAM_LENGTH = 1;

const MIN_CATEGORY_PARAM_LENGTH = 1;

export const useFilterHelper = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const startDateParam = searchParams.get(STATEMENTS_FILTER_PARAMS.from);

  const endDateParam = searchParams.get(STATEMENTS_FILTER_PARAMS.to);

  const movementParam = searchParams.getAll(STATEMENTS_FILTER_PARAMS.movement);

  const categoryParam = searchParams.getAll(STATEMENTS_FILTER_PARAMS.category);

  const handleDateInput = ({
    inputParam,
    inputValue,
  }: HandleDateInputProps) => {
    const date = handleNormalizeDate(inputValue);

    if (!isValidDate(date)) return;

    searchParams.set(inputParam, inputValue);

    setSearchParams(searchParams);
  };

  const handleRangeSelect = (range: number) => {
    const normalizedDate = handleNormalizeDate(TODAY_INPUT);
    const newFromDate = sub(normalizedDate, { days: range });
    searchParams.set(
      STATEMENTS_FILTER_PARAMS.from,
      formatToInputDate(newFromDate),
    );
    searchParams.set(STATEMENTS_FILTER_PARAMS.to, TODAY_INPUT);
    setSearchParams(searchParams);
  };

  const getSelectedRange = () => {
    const normalizedFrom = handleNormalizeDate(startDateParam);
    const normalizedTo = handleNormalizeDate(endDateParam);

    return differenceInDays(normalizedTo, normalizedFrom);
  };

  const handleSearch = (search: string) => {
    if (search.length) {
      searchParams.set(STATEMENTS_FILTER_PARAMS.search, search);
    } else {
      searchParams.delete(STATEMENTS_FILTER_PARAMS.search);
    }
    setSearchParams(searchParams);
  };

  const clearFilter = () => {
    setSearchParams();
  };

  const handleMovement = (movement: HandleMovementProps) => {
    if (
      !movementParam.includes(movement) &&
      movementParam.length === MIN_TYPE_PARAM_LENGTH
    ) {
      searchParams.delete(STATEMENTS_FILTER_PARAMS.movement);
      return setSearchParams(searchParams);
    }

    if (
      movementParam.includes(movement) &&
      movementParam.length === MIN_TYPE_PARAM_LENGTH
    ) {
      const filteredMovement = STATEMENTS_FILTER_MOVEMENTS.filter(
        (filterMovement) => filterMovement !== movement,
      );
      searchParams.set(
        STATEMENTS_FILTER_PARAMS.movement,
        filteredMovement.toString(),
      );
      return setSearchParams(searchParams);
    }

    const filteredMovement = STATEMENTS_FILTER_MOVEMENTS.filter(
      (filterMovement) => filterMovement !== movement,
    );
    searchParams.append(
      STATEMENTS_FILTER_PARAMS.movement,
      filteredMovement.toString(),
    );
    setSearchParams(searchParams);
  };

  const handleCategories = (category: HandleCategoriesProps) => {
    if (!categoryParam.length) {
      searchParams.append(STATEMENTS_FILTER_PARAMS.category, category);
    }

    if (categoryParam.includes(category)) {
      searchParams.delete(STATEMENTS_FILTER_PARAMS.category);
      categoryParam.forEach((filteredCategory) => {
        if (filteredCategory !== category)
          searchParams.append(
            STATEMENTS_FILTER_PARAMS.category,
            filteredCategory,
          );
      });
    }

    if (
      !categoryParam.includes(category) &&
      categoryParam.length >= MIN_CATEGORY_PARAM_LENGTH
    ) {
      searchParams.append(STATEMENTS_FILTER_PARAMS.category, category);
    }

    if (
      !categoryParam.includes(category) &&
      categoryParam.length === STATEMENTS_FILTER_CATEGORIES.length
    ) {
      searchParams.delete(STATEMENTS_FILTER_PARAMS.category);
    }

    setSearchParams(searchParams);
  };

  return {
    handleDateInput,
    handleRangeSelect,
    getSelectedRange,
    handleSearch,
    clearFilter,
    handleMovement,
    handleCategories,
  };
};
