import { MutationConfig } from "@/lib";
import { API } from "@/utils/utility";
import { toast } from "@hyperlocal/vital2";
import { useMutation } from "@tanstack/react-query";

const cancelPaymentSchedule = async (idSchedule: string) => {
  const response = await API.financePayments.delete(
    `/api/Payment/deletePaymentScheduler?idSchedule=${idSchedule}`,
  );

  return response.data;
};

export const useCancelPayment = (
  config?: MutationConfig<typeof cancelPaymentSchedule>,
) => {
  return useMutation({
    mutationFn: (idSchedule: string) => cancelPaymentSchedule(idSchedule),
    onError: () => {
      toast({
        title: "Não foi possível concluir a operação, tente mais tarde.",
        variant: "error",
      });
    },
    ...config,
  });
};
