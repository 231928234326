import { useRef } from "react";
import { ScrollArea, VerticalLogo } from "@/components";
import { TransactionItemTransformed as Item } from "@/types";
import { descriptionStyles, formatBrDate, usePrint } from "@/utils";
import { formatCurrency } from "@/utils/format";
import { Button, Sheet, SheetTriggerProps } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";

interface WowTransactionReceiptProps extends SheetTriggerProps {
  item: Item;
}

export const WowTransactionReceipt = ({
  item,
  ...rest
}: WowTransactionReceiptProps) => {
  const { definitionTerm, definitionDetail, definitionContainer } =
    descriptionStyles();

  const componentRef = useRef<HTMLDivElement>(null);

  const status = item.movement === "Entrada" ? "Recebido" : "Enviado";

  const handlePrint = usePrint({
    content: () => componentRef.current,
  });

  return (
    <Sheet.Root>
      <Sheet.Trigger {...rest} className="flex w-full" />
      <Sheet.Content className="!p-0">
        <Sheet.Header className="p-6">
          <Sheet.Title>Comprovante</Sheet.Title>
        </Sheet.Header>
        <div
          className="flex w-full flex-col items-center overflow-hidden"
          ref={componentRef}
        >
          <ScrollArea className="flex h-full w-full flex-col px-6" id="infos">
            <VerticalLogo />
            <div className="flex w-full flex-col items-center overflow-hidden">
              <h5 className="font-base text-sm/md font-bold text-neutral-darkest">
                {item.type}
              </h5>
              <h5 className="mb-6 font-base text-sm/md font-bold text-primary-main">
                {status}
              </h5>
              <div className="flex h-full w-full flex-col overflow-hidden">
                <hr className="h-[1px] w-full bg-neutral-light" />
                <dl className="my-4 h-full w-full">
                  <dt className={definitionTerm()}>Descrição</dt>
                  <dd
                    className={twMerge(definitionDetail(), "mt-1 text-start")}
                  >
                    {item.description}
                  </dd>
                </dl>
                <hr className="h-[1px] w-full bg-neutral-light" />
                <dl className={definitionContainer()}>
                  <dt className={definitionTerm()}>Data</dt>
                  <dd className={twMerge(definitionDetail())}>
                    {formatBrDate(new Date(item.createdAt))}
                  </dd>
                  <dt className={definitionTerm()}>Valor</dt>
                  <dd className={twMerge(definitionDetail())}>
                    {formatCurrency(item.value)}
                  </dd>
                </dl>
              </div>
            </div>
          </ScrollArea>
        </div>
        <Sheet.Footer className="mt-auto !flex !flex-col gap-2">
          <Button.Root className="m-6 mt-auto" onClick={handlePrint}>
            <Button.Slot name="GeralPrint" />
            Imprimir comprovante
          </Button.Root>
        </Sheet.Footer>
      </Sheet.Content>
    </Sheet.Root>
  );
};
