import { ComponentProps } from "react";

export function ArrowRight(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.875 17.125a.72.72 0 01-.225-.525c0-.2.075-.375.225-.525L12.95 12 8.875 7.925A.72.72 0 018.65 7.4c0-.2.075-.375.225-.525A.72.72 0 019.4 6.65c.2 0 .375.075.525.225l4.5 4.5a.824.824 0 01.188.287.941.941 0 010 .675.821.821 0 01-.188.288l-4.5 4.5a.72.72 0 01-.525.225.72.72 0 01-.525-.225z"
        fill="#0047F6"
      />
    </svg>
  );
}
