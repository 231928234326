import type { TransactionItem } from "@/types";
import { API, useAccountStore } from "@/utils";
import { transformStatementsResponse } from "@/utils/transform";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";

type GetWowStatementsParams = {
  startDate: string;
  endDate: string;
  page: number;
  financialOperationalKey: string;
  vertical: string;
};

const getWowStatements = async (params: GetWowStatementsParams) => {
  if (!params.financialOperationalKey) {
    throw new Error("Usuário não tem conta wow.");
  }
  const response = await API.internalStatements.get("/statements/hubprepaid", {
    params: {
      startDate: params.startDate,
      endDate: params.endDate,
      page: params.page,
      financialOperationalKey: params.financialOperationalKey,
      Vertical: params.vertical,
    },
  });
  const data = response.data;

  const tmp = data.statements.map(
    ({ createdAt, description, id, movement, type, value }) => {
      const tempObj = {
        Id: id,
        CreatedAt: createdAt,
        Description: description,
        Movement: movement,
        Type: type,
        Value: value,
      };

      return tempObj as TransactionItem;
    },
  );

  return {
    ...data,
    statements: transformStatementsResponse(tmp),
  };
};

export const useWowStatements = (
  params: Pick<GetWowStatementsParams, "startDate" | "endDate">,
) => {
  const { account, fok } = useAccountStore();

  return useSuspenseInfiniteQuery({
    queryFn: ({ pageParam }) =>
      getWowStatements({
        startDate: params.startDate,
        endDate: params.endDate,
        page: pageParam + 1,
        financialOperationalKey: fok ? String(fok) : undefined,
        vertical: account.vertical === "BANKING" ? "wow" : "avec",
      }),
    queryKey: ["wow-statements", params],
    initialPageParam: 0,
    getNextPageParam: (_lastPage, _allPages, lastPageParam) => {
      return lastPageParam + 1;
    },
  });
};
