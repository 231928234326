import { Payment } from "@/types/commitments";
import { formatBrDate, getGroupedDate } from "@/utils";
import { formatCurrency, normalizeNFD } from "@/utils/format";

const handleSearch = (items: Payment[], searchParam?: string): Payment[] => {
  if (!searchParam) return items;

  const search = normalizeNFD(searchParam);

  const result = items.reduce((prev, cur) => {
    const amount = formatCurrency(cur.amount);
    if (amount.includes(search)) prev.push(cur);

    const description = normalizeNFD(cur.description);

    if (description.includes(search)) prev.push(cur);

    const scheduleDate = formatBrDate(new Date(cur.scheduleDate));

    if (scheduleDate.includes(search)) prev.push(cur);

    const assignor = normalizeNFD(cur.assignor);

    if (assignor.includes(search)) prev.push(cur);

    return prev;
  }, []);

  return [...new Set(result)];
};

export type PaymentsTransformed = {
  totalAmount: number;
  items: Record<string, Payment[]>;
  totalLength: number;
};

const notAllowedResultResponses = {
  accountNotAvailable: "Account not available to complete the transaction",
};

const shouldNotDisplayPayment = (payment: Payment) => {
  return (
    payment.status === "SCHEDULE_EXECUTED" &&
    Object.values(notAllowedResultResponses).includes(payment.result)
  );
};

const transformPayments = (items: Payment[]) => {
  const result = items.reduce<PaymentsTransformed>(
    (acc, cur) => {
      if (shouldNotDisplayPayment(cur)) return acc;

      if (cur.status === "SCHEDULED") {
        acc.totalAmount += cur.amount;
        acc.totalLength += 1;
      }

      const scheduledAt = new Date(cur.scheduleDate);

      const groupedDate = getGroupedDate(scheduledAt);

      if (!acc.items[groupedDate]) acc.items[groupedDate] = [];

      acc.items[groupedDate].push(cur);
      return acc;
    },
    {
      totalAmount: 0,
      items: {},
      totalLength: 0,
    },
  );

  return result;
};

export const handlePaymentResponse = (items: Payment[], search?: string) => {
  const searchedResponse = handleSearch(items, search);
  const transformed = transformPayments(searchedResponse);

  return transformed;
};
