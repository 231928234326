import { ExportRequest, StatementsRequest } from "@/types";
import { PaymentScheduleListRequest } from "@/types/commitments";

export const QUERY_KEYS = {
  paymentDefault: ["getPayments"],
  getStatements: ({
    endDate,
    startDate,
    movement,
    type,
    accountId,
  }: StatementsRequest) => [
    "getStatements",
    accountId,
    movement,
    startDate,
    endDate,
    type,
  ],
  exportData: ({
    accountId,
    exportType,
    startDate,
    endDate,
  }: ExportRequest) => ["export", accountId, exportType, startDate, endDate],
  getPaymentsSchedule: () => [...QUERY_KEYS.paymentDefault, "list"],
  getInfinitePaymentsSchedule: (params?: PaymentScheduleListRequest) => [
    ...QUERY_KEYS.paymentDefault,
    "infinite-list",
    params?.from,
    params?.to,
  ],
};
