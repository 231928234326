import { globalQueryClient } from "@/utils";
import {
  QueryClient,
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

export const queryClient = new QueryClient({
  ...globalQueryClient.getDefaultOptions(),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export type RequestError = AxiosError<unknown>;

export type ExtractFnReturnType<
  FnType extends (...args: unknown[]) => unknown,
> = Awaited<ReturnType<FnType>>;

export type QueryConfig<QueryFnType extends (...args: unknown[]) => unknown> =
  Omit<
    UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
    "queryKey" | "queryFn"
  >;

export type MutationConfig<
  FetcherFnType extends (...args: unknown[]) => unknown,
> = UseMutationOptions<
  Awaited<ReturnType<FetcherFnType>>,
  RequestError,
  Parameters<FetcherFnType>[0]
>;
