import { useReducer } from "react";
import {
  formatToInputDate,
  handleNormalizeDate,
  isValidDate,
  STATEMENTS_FILTER_CATEGORIES,
  STATEMENTS_FILTER_MOVEMENTS,
  STATEMENTS_FILTER_PARAMS,
  TODAY_INPUT,
} from "@/utils";
import { differenceInDays, sub } from "date-fns";
import { useSearchParams } from "react-router-dom";

const MIN_MOVEMENT_LENGTH = 1;

const MIN_CATEGORY_PARAM_LENGTH = 1;

type Movement = (typeof STATEMENTS_FILTER_MOVEMENTS)[number];

export type Category = (typeof STATEMENTS_FILTER_CATEGORIES)[number];

type StatementFilter = {
  startDate: string;
  endDate: string;
  movement: Movement[];
  categories: Category[];
};

type StatementAction =
  | { type: "SET_FROM_DATE"; payload: string }
  | { type: "SET_TO_DATE"; payload: string }
  | { type: "SET_MOVEMENT"; payload: Movement }
  | { type: "SET_CATEGORIES"; payload: Category }
  | { type: "SET_RANGE"; payload: number }
  | { type: "SET_RESET" };

const RESET_VALUES: StatementFilter = {
  endDate: "",
  startDate: "",
  movement: ["IN", "OUT"],
  categories: [],
};

const handleDateInput = (dateValue: string) => {
  const date = handleNormalizeDate(dateValue);

  if (!isValidDate(date)) return "";

  return dateValue;
};

const handleRange = (range: number) => {
  const normalizedDate = handleNormalizeDate(TODAY_INPUT);
  const newStartDate = sub(normalizedDate, { days: range });

  return {
    endDate: TODAY_INPUT,
    startDate: formatToInputDate(newStartDate),
  };
};

const handleMovement = (stateMovement: Movement[], movement: Movement) => {
  if (
    !stateMovement.includes(movement) &&
    stateMovement.length === MIN_MOVEMENT_LENGTH
  ) {
    return RESET_VALUES.movement;
  }

  if (
    stateMovement.includes(movement) &&
    stateMovement.length === MIN_MOVEMENT_LENGTH
  ) {
    const filteredMovement = STATEMENTS_FILTER_MOVEMENTS.filter(
      (filterMovement) => filterMovement !== movement,
    );
    return filteredMovement;
  }
  const filteredMovement = STATEMENTS_FILTER_MOVEMENTS.filter(
    (filterMovement) => filterMovement !== movement,
  );

  return filteredMovement;
};

const handleCategories = (stateCategories: Category[], category: Category) => {
  const result = [];

  if (!stateCategories.length)
    STATEMENTS_FILTER_CATEGORIES.forEach((filteredCategory) => {
      if (filteredCategory !== category) result.push(filteredCategory);
    });

  if (stateCategories.includes(category)) {
    result.length = 0;
    stateCategories.forEach((filteredCategory) => {
      if (filteredCategory !== category) result.push(filteredCategory);
    });
  }

  if (
    !stateCategories.includes(category) &&
    stateCategories.length >= MIN_CATEGORY_PARAM_LENGTH
  ) {
    result.push(category);
  }

  if (
    !stateCategories.includes(category) &&
    stateCategories.length === STATEMENTS_FILTER_CATEGORIES.length - 1
  )
    result.length = 0;

  return result;
};

const reducer = (state: StatementFilter, action: StatementAction) => {
  switch (action.type) {
    case "SET_FROM_DATE":
      return {
        ...state,
        startDate: handleDateInput(action.payload),
      };
    case "SET_TO_DATE":
      return {
        ...state,
        endDate: handleDateInput(action.payload),
      };
    case "SET_CATEGORIES":
      return {
        ...state,
        categories: handleCategories(state.categories, action.payload),
      };
    case "SET_MOVEMENT":
      return {
        ...state,
        movement: handleMovement(state.movement, action.payload),
      };
    case "SET_RANGE":
      return {
        ...state,
        ...handleRange(action.payload),
      };
    case "SET_RESET":
      return RESET_VALUES;
    default:
      return RESET_VALUES;
  }
};

export const useStatementFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const startDateParam = searchParams.get(STATEMENTS_FILTER_PARAMS.from) || "";

  const endDateParam = searchParams.get(STATEMENTS_FILTER_PARAMS.to) || "";

  const movementParam = searchParams.getAll(STATEMENTS_FILTER_PARAMS.movement);

  const categoryParam = searchParams.getAll(STATEMENTS_FILTER_PARAMS.category);

  const initialState: StatementFilter = {
    endDate: endDateParam,
    startDate: startDateParam,
    movement: movementParam.length
      ? (movementParam as Movement[])
      : RESET_VALUES.movement,
    categories: categoryParam as Category[],
  };

  const [filterState, dispatch] = useReducer(reducer, initialState);

  const getSelectedRange = () => {
    const normalizedFrom = handleNormalizeDate(filterState.startDate);
    const normalizedTo = handleNormalizeDate(filterState.endDate);

    return differenceInDays(normalizedTo, normalizedFrom);
  };

  const resetFilters = () => {
    dispatch({ type: "SET_RESET" });
    setSearchParams();
  };

  const handleFilter = () => {
    if (filterState.movement.length === MIN_MOVEMENT_LENGTH)
      searchParams.set(
        STATEMENTS_FILTER_PARAMS.movement,
        filterState.movement[0],
      );
    else searchParams.delete(STATEMENTS_FILTER_PARAMS.movement);

    if (filterState.categories.length)
      filterState.categories.forEach((category) =>
        searchParams.append(STATEMENTS_FILTER_PARAMS.category, category),
      );

    if (filterState.startDate && filterState.endDate) {
      searchParams.set(STATEMENTS_FILTER_PARAMS.from, filterState.startDate);
      searchParams.set(STATEMENTS_FILTER_PARAMS.to, filterState.endDate);
    }

    setSearchParams(searchParams);
  };

  return {
    filterState,
    dispatch,
    getSelectedRange,
    resetFilters,
    handleFilter,
  };
};
