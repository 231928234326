import { COMMITMENT_FILTER_PARAMS } from "@/utils";
import { Button, TextField } from "@hyperlocal/vital2";
import { useSearchParams } from "react-router-dom";
import { tv } from "tailwind-variants";
import { useFilterHelper } from "./helper";

const style = tv({
  slots: {
    title: "text-neutral-darkest font-bold text-sm/[30px]",
  },
});

export const Filter = () => {
  const { title } = style();

  const [searchParams] = useSearchParams();

  const { handleDateInput, handleSearch, clearFilter } = useFilterHelper();

  const startDateParam = searchParams.get(COMMITMENT_FILTER_PARAMS.from) || "";

  const endDateParam = searchParams.get(COMMITMENT_FILTER_PARAMS.to) || "";

  const searchParam = searchParams.get(COMMITMENT_FILTER_PARAMS.search) || "";

  return (
    <div className="flex min-h-[600px] flex-col gap-inset-lg rounded-sm bg-neutral-white p-inset-lg">
      <TextField.Root className="mobile:hidden">
        <TextField.Input
          value={searchParam}
          onChange={({ target }) => handleSearch(target.value)}
          placeholder="Buscar..."
        />
        <TextField.Slot name="SearchNormal" />
      </TextField.Root>
      <div>
        <h5 className={title()}>Período personalizado</h5>
        <div className="mt-4 flex gap-4">
          <div className="w-full">
            <TextField.Label htmlFor="from-date">De</TextField.Label>
            <TextField.Root>
              <TextField.Input
                type="date"
                id="from-date"
                value={startDateParam}
                max={endDateParam}
                onChange={({ target }) =>
                  handleDateInput({
                    inputParam: "from",
                    inputValue: target.value,
                  })
                }
              />
              <TextField.Slot name="GeralCalendar" className="cursor-pointer" />
            </TextField.Root>
          </div>
          <div className="w-full">
            <TextField.Label htmlFor="to-date">Até</TextField.Label>
            <TextField.Root>
              <TextField.Input
                type="date"
                id="to-date"
                value={endDateParam}
                min={startDateParam}
                onChange={({ target }) =>
                  handleDateInput({
                    inputParam: "to",
                    inputValue: target.value,
                  })
                }
              />
              <TextField.Slot name="GeralCalendar" className="cursor-pointer" />
            </TextField.Root>
          </div>
        </div>
      </div>
      <Button.Root onClick={clearFilter} variant="link" className="mt-auto">
        Limpar filtro
      </Button.Root>
    </div>
  );
};
