import {
  COMMITMENT_FILTER_PARAMS,
  COMMITMENT_FILTER_STATUS,
  handleNormalizeDate,
  isValidDate,
} from "@/utils";
import { useSearchParams } from "react-router-dom";

type HandleDateInputProps = {
  inputValue: string;
  inputParam: Extract<keyof typeof COMMITMENT_FILTER_PARAMS, "from" | "to">;
};

type HandleStatusProps = (typeof COMMITMENT_FILTER_STATUS)[number];

export const useFilterHelper = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleDateInput = ({
    inputParam,
    inputValue,
  }: HandleDateInputProps) => {
    const date = handleNormalizeDate(inputValue);

    if (!isValidDate(date)) return;

    searchParams.set(inputParam, inputValue);

    setSearchParams(searchParams);
  };

  const handleSearch = (search: string) => {
    if (search.length) {
      searchParams.set(COMMITMENT_FILTER_PARAMS.search, search);
    } else {
      searchParams.delete(COMMITMENT_FILTER_PARAMS.search);
    }
    setSearchParams(searchParams);
  };

  const handleStatus = (status: HandleStatusProps) => {
    const statusParam = searchParams.getAll(COMMITMENT_FILTER_PARAMS.status);

    if (!statusParam.includes(status)) {
      searchParams.append(COMMITMENT_FILTER_PARAMS.status, status);
    } else {
      searchParams.delete(COMMITMENT_FILTER_PARAMS.status);
      statusParam.forEach((statusType) => {
        if (statusType !== status)
          searchParams.append(COMMITMENT_FILTER_PARAMS.status, statusType);
      });
    }

    setSearchParams(searchParams);
  };

  const clearFilter = () => setSearchParams();

  return {
    handleDateInput,
    handleSearch,
    handleStatus,
    clearFilter,
  };
};
