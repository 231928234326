import { formatBoleto, isValidBoleto } from "@brazilian-utils/brazilian-utils";

// remove acentos para busca
export const normalizeNFD = (text: string) => {
  try {
    if (!text) return "";
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  } catch (error) {
    console.error(error);
  }
};

export const formatCurrency = (value: number) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);

export const formatBankSlip = (barCode: string) => {
  if (isValidBoleto(barCode)) return formatBoleto(barCode);

  return barCode;
};
