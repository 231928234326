"use client";

import * as React from "react";
import * as TogglePrimitive from "@radix-ui/react-toggle";
import { twMerge } from "tailwind-merge";
import { tv, VariantProps } from "tailwind-variants";

const toggleVariants = tv({
  base: "inline-flex items-center justify-center text-xs/4 font-medium transition-colors hover:bg-primary-lighter hover:text-primary-main  disabled:pointer-events-none disabled:opacity-50 data-[state=on]:bg-primary-main data-[state=on]:text-neutral-white font-base p-inset-x2s border border-primary-main rounded-sm text-primary-main",
  variants: {
    variant: {
      default: "bg-transparent",
      outline:
        "border border-input bg-transparent hover:bg-accent hover:text-accent-foreground",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

const Toggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> &
    VariantProps<typeof toggleVariants>
>(({ className, variant, ...props }, ref) => (
  <TogglePrimitive.Root
    ref={ref}
    className={twMerge(toggleVariants({ variant, className }))}
    {...props}
  />
));

Toggle.displayName = TogglePrimitive.Root.displayName;

export { Toggle, toggleVariants };
