import { ElementRef, useRef } from "react";
import { ScrollArea, VerticalLogo } from "@/components";
import { useCancelPayment } from "@/features/commitments/services";
import { queryClient } from "@/lib";
import { Payment, PaymentScheduleStatus } from "@/types/commitments";
import { descriptionStyles, formatBrDate, usePrint } from "@/utils";
import { formatBankSlip, formatCurrency } from "@/utils/format";
import { QUERY_KEYS } from "@/utils/react-query-keys";
import { Button, Sheet, SheetTriggerProps, toast } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";

interface CommitmentReceiptProps extends SheetTriggerProps {
  payment: Payment;
}

const paymentStatus: Record<PaymentScheduleStatus, string> = {
  SCHEDULED: "Agendado",
  SCHEDULE_EXECUTED: "Realizado",
  SCHEDULE_CANCELED: "Cancelado",
  SCHEDULE_NOT_EXECUTED: "Não pago",
};

const getPaymentStatus = (payment: Payment) => {
  if (payment.status === "SCHEDULE_EXECUTED" && !!payment.result)
    return paymentStatus.SCHEDULE_NOT_EXECUTED;

  return paymentStatus[payment.status];
};

export const CommitmentReceipt = ({
  payment,
  ...rest
}: CommitmentReceiptProps) => {
  const { definitionTerm, definitionDetail, definitionContainer } =
    descriptionStyles();

  const componentRef = useRef<HTMLDivElement>(null);
  const sheetCloseRef = useRef<ElementRef<typeof Sheet.Close>>(null);

  const handlePrint = usePrint({
    content: () => componentRef.current,
  });

  const cancelMutation = useCancelPayment({
    onSuccess: async () => {
      toast({
        title: "Agendamento cancelado.",
        variant: "success",
      });
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.getInfinitePaymentsSchedule(),
      });
      sheetCloseRef.current.click();
    },
  });

  const handleCancel = async () => {
    await cancelMutation.mutateAsync(payment.idSchedule);
  };

  const isPending = payment.status === "SCHEDULED";

  const isLoading = cancelMutation.isPending;

  return (
    <Sheet.Root>
      <Sheet.Trigger {...rest} className="flex w-full" />
      <Sheet.Content
        onEscapeKeyDown={(e) => {
          if (isLoading) e.preventDefault();
        }}
        onPointerDownOutside={(e) => {
          if (isLoading) e.preventDefault();
        }}
        className={twMerge(
          isLoading && "[&>[data-role=close-sheet]]:hidden",
          "!p-0",
        )}
      >
        <Sheet.Header className="p-6 pb-0">
          <Sheet.Title>Comprovante</Sheet.Title>
          <Sheet.Close ref={sheetCloseRef} />
        </Sheet.Header>
        <div
          className="flex w-full flex-col items-center overflow-hidden"
          ref={componentRef}
        >
          <ScrollArea className="flex h-full w-full flex-col px-6" id="infos">
            <VerticalLogo />
            <div className="flex w-full flex-col items-center overflow-hidden">
              <h5 className="font-base text-sm/md font-bold text-neutral-darkest">
                Pagamento de boleto
              </h5>
              <h5 className="mb-6 font-base text-sm/md font-bold text-primary-main">
                {getPaymentStatus(payment)}
              </h5>
              <div className="flex h-full w-full flex-col overflow-hidden">
                <hr className="h-[1px] w-full bg-neutral-light" />
                <dl className="my-4 h-full w-full">
                  <dt className={definitionTerm()}>Mensagem</dt>
                  <dd
                    className={twMerge(definitionDetail(), "mt-1 text-start")}
                  >
                    {payment.description}
                  </dd>
                </dl>
                <hr className="h-[1px] w-full bg-neutral-light" />
                <dl className={definitionContainer()}>
                  <div className="col-span-2">
                    <dt className={definitionTerm()}>Código de barras</dt>
                    <dd
                      className={twMerge(
                        definitionDetail(),
                        "break-all text-start",
                      )}
                    >
                      {formatBankSlip(payment.barCodeNumber)}
                    </dd>
                  </div>
                  <dt className={definitionTerm()}>Data da solicitação</dt>
                  <dd className={twMerge(definitionDetail())}>
                    {formatBrDate(new Date(payment.createdAt))}
                  </dd>
                  <dt className={definitionTerm()}>Data do agendamento</dt>
                  <dd className={twMerge(definitionDetail())}>
                    {formatBrDate(new Date(payment.scheduleDate))}
                  </dd>
                  <dt className={definitionTerm()}>Valor</dt>
                  <dd className={twMerge(definitionDetail())}>
                    {formatCurrency(payment.amount)}
                  </dd>
                  <dt className={definitionTerm()}>Multa</dt>
                  <dd className={twMerge(definitionDetail())}>
                    {formatCurrency(payment.fine)}
                  </dd>
                  <dt className={definitionTerm()}>Juros</dt>
                  <dd className={twMerge(definitionDetail())}>
                    {formatCurrency(payment.interest)}
                  </dd>
                  <dt className={definitionTerm()}>Desconto</dt>
                  <dd className={twMerge(definitionDetail())}>
                    {formatCurrency(payment.discount)}
                  </dd>
                </dl>
              </div>
            </div>
          </ScrollArea>
        </div>
        <Sheet.Footer className="mt-auto !flex !flex-col gap-2">
          {isPending && (
            <Button.Root
              className="!py-0 text-status-error-dark hover:text-status-error-dark focus:text-status-error-dark active:text-status-error-dark"
              variant="link"
              onClick={handleCancel}
              isLoading={isLoading}
            >
              Cancelar pagamento
            </Button.Root>
          )}
          <Button.Root
            className="m-6 mt-auto"
            disabled={isLoading}
            onClick={handlePrint}
          >
            <Button.Slot name="GeralPrint" />
            Imprimir comprovante
          </Button.Root>
        </Sheet.Footer>
      </Sheet.Content>
    </Sheet.Root>
  );
};
