import { Suspense } from "react";
import { ErrorFeedback } from "@/components";
import { useInfinitePayments } from "@/features/commitments/services";
import { formatCurrency } from "@/utils/format";
import { CommitmentList } from "../commitment-list";
import { LoadingPayments } from "../loading-payments";

const CommitmentsDetailsComponent = () => {
  const { data } = useInfinitePayments();

  const getMessageFeedBack = () => {
    const totalLength = data.pages.totalLength;

    if (!totalLength) return "Não há nenhum compromisso programado.";

    return totalLength > 1
      ? `São ${totalLength} compromissos programados`
      : "Há 1 compromisso programado";
  };

  return (
    <div className="flex h-full w-full flex-col rounded-sm bg-neutral-white">
      <div className="flex w-full flex-col items-center p-inset-lg pb-0 mobile:p-inset-md mobile:pb-0">
        <span className="font-base text-xs/4 text-neutral-darker mobile:text-x2s/[14px]">
          Total de compromissos pendentes
        </span>
        <h2 className="mt-2 font-base text-xl/[50px] font-bold text-neutral-darkest mobile:text-lg/10">
          {formatCurrency(data.pages.totalAmount)}
        </h2>
        <hr className="my-2 h-[1px] w-full max-w-[70%] bg-neutral-lighter" />
        <span className="self-center font-base text-[14px] text-neutral-darkest">
          {getMessageFeedBack()}
        </span>
      </div>

      <CommitmentList />
    </div>
  );
};

export const CommitmentsDetails = () => {
  return (
    <ErrorFeedback>
      <Suspense fallback={<LoadingPayments />}>
        <CommitmentsDetailsComponent />
      </Suspense>
    </ErrorFeedback>
  );
};
