import {
  StatementListResponse,
  StatementTransformedResponse,
  TransactionItem,
  TransactionItemTransformed,
} from "@/types";
import { formatBrDate, getGroupedDate } from "@/utils";
import { formatCurrency, normalizeNFD } from "@/utils/format";
import { transformStatementsResponse } from "@/utils/transform";

const handleSearch = (items: TransactionItem[], searchParam?: string) => {
  if (!searchParam) return items;

  const search = normalizeNFD(searchParam.toLowerCase());

  const result = items.reduce((previous, current) => {
    const participant = normalizeNFD(current?.Participant).toLowerCase();

    if (participant && participant.includes(search)) previous.push(current);

    const description = normalizeNFD(current?.Description).toLowerCase();
    if (description && description.includes(search)) previous.push(current);

    const amount = formatCurrency(current.Value);
    if (amount.includes(search)) previous.push(current);

    const date = formatBrDate(new Date(current.CreatedAt));
    if (date.includes(search)) previous.push(current);

    return previous;
  }, []);

  return [...new Set(result)];
};

export const transformResponse = (items: TransactionItemTransformed[]) => {
  const result = items.reduce<StatementTransformedResponse>((acc, cur) => {
    const createdAt = new Date(cur.createdAt);

    const groupedDate = getGroupedDate(createdAt);

    if (!acc[groupedDate]) acc[groupedDate] = [];

    acc[groupedDate].push(cur);

    return acc;
  }, {});

  return result;
};

export const handleStatementsResponse = (
  response: StatementListResponse["Items"],
  search?: string,
) => {
  const searchedResponse = handleSearch(response, search);

  const transformed = transformStatementsResponse(searchedResponse);

  return transformResponse(transformed);
};
