import AvecLogo from "@/assets/Avec.png";
import CrossXLogo from "@/assets/CrossX.png";
import HyperlocalLogo from "@/assets/Hyperlocal.png";
import { TransactionItemTransformed, Vertical } from "@/types";
import { Account } from "@/utils";
import { formatCPF } from "@brazilian-utils/brazilian-utils";
import { toast } from "@hyperlocal/vital2";
import { addMinutes, format } from "date-fns";
import JSpdf from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";

type AccountInfo = {
  bankId: string;
  accountNumber: string;
};

type OfxParams = {
  startDate: string;
  endDate: string;
  data: TransactionItemTransformed[];
  account: AccountInfo;
};

export const generateOFXBlob = ({
  data,
  account,
  endDate,
  startDate,
}: OfxParams) => {
  const dateTimeStart = moment(moment(startDate).format("YYYY-MM-DD")).format(
    "YYYYMMDDHHmmss",
  );
  const dateTimeEnd = moment(moment(endDate).format("YYYY-MM-DD")).format(
    "YYYYMMDD235959",
  );
  const dateTimeNow = moment().format("YYYYMMDDHHmmss");

  // Cabeçalho do arquivo OFX
  const header = `OFXHEADER:100
DATA:OFXSGML
VERSION:102
SECURITY:NONE
ENCODING:USASCII
CHARSET:1252
COMPRESSION:NONE
OLDFILEUID:NONE
NEWFILEUID:NONE

`;

  // Corpo do documento OFX
  const body = `
<OFX>
  <SIGNONMSGSRSV1>
    <SONRS>
      <STATUS>
        <CODE>0</CODE>
        <SEVERITY>INFO</SEVERITY>
        <MESSAGE>Success</MESSAGE>
      </STATUS>
      <DTSERVER>${dateTimeNow}</DTSERVER>
      <LANGUAGE>ENG</LANGUAGE>
    </SONRS>
  </SIGNONMSGSRSV1>
  <BANKMSGSRSV1>
    <STMTTRNRS>
      <TRNUID>0</TRNUID>
      <STATUS>
        <CODE>0</CODE>
        <SEVERITY>INFO</SEVERITY>
        <MESSAGE>Success</MESSAGE>
      </STATUS>
      <STMTRS>
        <CURDEF>BRL</CURDEF>
        <BANKACCTFROM>
          <BANKID>${account.bankId}</BANKID>
          <ACCTID>${account.accountNumber}</ACCTID>
          <ACCTTYPE>CHECKING</ACCTTYPE>
        </BANKACCTFROM>
        <BANKTRANLIST>
          <DTSTART>${dateTimeStart}</DTSTART>
          <DTEND>${dateTimeEnd}</DTEND>`;

  const transactions = data
    .map(
      (item) => `
          <STMTTRN>
            <TRNTYPE>${item.movement === "Entrada" ? "CREDIT" : "DEBIT"}</TRNTYPE>
            <DTPOSTED>${moment(new Date(item.createdAt)).format("YYYYMMDDHHmmss")}</DTPOSTED>
            <TRNAMT>${item.value}</TRNAMT>
            <FITID>${item.id}</FITID>
            ${item.participant && `<NAME>${item.participant}</NAME>`}
            ${item.description && `<MEMO>${item.description}</MEMO>`}
          </STMTTRN>`,
    )
    .join("");

  const footer = `
        </BANKTRANLIST>
        <LEDGERBAL>
          <BALAMT>0</BALAMT>
          <DTASOF>${dateTimeNow}</DTASOF>
        </LEDGERBAL>
        <AVAILBAL>
          <BALAMT>0</BALAMT>
          <DTASOF>${dateTimeNow}</DTASOF>
        </AVAILBAL>
      </STMTRS>
    </STMTTRNRS>
  </BANKMSGSRSV1>
</OFX>`;

  const content = header + body + transactions + footer;

  try {
    const blob = new Blob([content], { type: "application/x-ofx" });
    return blob;
  } catch (error) {
    toast({
      title: "Não foi possível baixar o arquivo",
    });
  }
};

// const generateOFX = (data) => {
//   // Cabeçalho do arquivo OFX
//   const header = `OFXHEADER:100
// DATA:OFXSGML
// VERSION:102
// SECURITY:NONE
// ENCODING:USASCII
// CHARSET:1252
// COMPRESSION:NONE
// OLDFILEUID:NONE
// NEWFILEUID:NONE

// `;

//   // Corpo do documento OFX
//   const body = `
// <OFX>
//   <SIGNONMSGSRSV1>
//     <SONRS>
//       <STATUS>
//         <CODE>0</CODE> <!-- Código de status 0 indica sucesso -->
//         <SEVERITY>INFO</SEVERITY> <!-- Severidade do status é informativa -->
//         <MESSAGE>Success</MESSAGE> <!-- Mensagem descritiva do status -->
//       </STATUS>
//       <DTSERVER>${new Date().toISOString()}</DTSERVER> <!-- Data e hora do servidor -->
//       <LANGUAGE>ENG</LANGUAGE> <!-- Idioma usado no documento -->
//     </SONRS>
//   </SIGNONMSGSRSV1>
//   <BANKMSGSRSV1>
//     <STMTTRNRS>
//       <TRNUID>0</TRNUID> <!-- Identificador único da transação -->
//       <STATUS>
//         <CODE>0</CODE> <!-- Código de status -->
//         <SEVERITY>INFO</SEVERITY> <!-- Severidade do status -->
//         <MESSAGE>Success</MESSAGE> <!-- Mensagem descritiva do status -->
//       </STATUS>
//       <STMTRS>
//         <CURDEF>BRL</CURDEF> <!-- Moeda padrão -->
//         <BANKACCTFROM>
//           <BANKID>001</BANKID> <!-- Código do banco -->
//           <ACCTID>123456789</ACCTID> <!-- Número da conta -->
//           <ACCTTYPE>CHECKING</ACCTTYPE> <!-- Tipo da conta -->
//         </BANKACCTFROM>
//         <BANKTRANLIST>
//           <DTSTART>${new Date(data[0].CreatedAt).toISOString()}</DTSTART> <!-- Data de início do período do extrato -->
//           <DTEND>${new Date(data[data.length - 1].CreatedAt).toISOString()}</DTEND> <!-- Data de fim do período do extrato -->`;

//   const transactions = data
//     .map(
//       (item) => `
//           <STMTTRN>
//             <TRNTYPE>${item.Movement === "Entrada" ? "CREDIT" : "DEBIT"}</TRNTYPE> <!-- Tipo da transação -->
//             <DTPOSTED>${new Date(item.CreatedAt).toISOString()}</DTPOSTED> <!-- Data da transação -->
//             <TRNAMT>${item.Value}</TRNAMT> <!-- Valor da transação -->
//             <FITID>${item.Id}</FITID> <!-- Identificador único da transação -->
//             <NAME>${item.Description}</NAME> <!-- Nome ou descrição da transação -->
//             <MEMO>${item.Participant}</MEMO> <!-- Memo ou observação sobre a transação -->
//           </STMTTRN>`,
//     )
//     .join("");

//   const footer = `
//         </BANKTRANLIST>
//         <LEDGERBAL>
//           <BALAMT>1000.00</BALAMT> <!-- Valor do saldo contábil -->
//           <DTASOF>${new Date().toISOString()}</DTASOF> <!-- Data do saldo contábil -->
//         </LEDGERBAL>
//         <AVAILBAL>
//           <BALAMT>1000.00</BALAMT> <!-- Valor do saldo disponível -->
//           <DTASOF>${new Date().toISOString()}</DTASOF> <!-- Data do saldo disponível -->
//         </AVAILBAL>
//       </STMTRS>
//     </STMTTRNRS>
//   </BANKMSGSRSV1>
// </OFX>`;
// };

export const handleDownloadError = () => {
  toast({
    title: "Não foi possível exportar o arquivo",
    variant: "error",
  });
};

export const downloadFile = ({
  blob,
  extension,
}: {
  extension: "pdf" | "ofx";
  blob: Blob;
}) => {
  try {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `extrato.${extension}`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.log("🚀 ~ file: downloadDocument.ts:167 ~ error:", error);
    handleDownloadError();
  }
};

export const formatDateHour = (dateString: string) => {
  const date = new Date(dateString);
  const createdAt = addMinutes(date, date.getTimezoneOffset());

  return format(createdAt, "dd/MM/yy - HH:mm");
};

export const downloadPDF = ({
  account,
  dates,
  transactions,
  vertical,
}: {
  account: Account;
  vertical: Vertical;
  transactions: TransactionItemTransformed[];
  dates: {
    startDate: Date;
    endDate: Date;
  };
}) => {
  const color = {
    avec: "#736EF5",
    crossX: "#001CA5",
    hyperlocal: "#0047f6",
  };

  const headers = [
    "Id",
    "Data",
    "Categoria",
    "Descrição",
    "Participante",
    "Tipo",
    "Valor (R$)",
  ];

  const logo: Record<Vertical, string> = {
    avec: AvecLogo,
    crossX: CrossXLogo,
    hyperlocal: HyperlocalLogo,
  };

  const startDate = format(dates.startDate, "dd/MM/yyyy");
  const endDate = format(dates.endDate, "dd/MM/yyyy");

  const { name, document } =
    account.companyInfoResponse || account.personInfoResponse;

  const pdf = new JSpdf("p", "mm", "a4");
  const pageWidth = pdf.internal.pageSize.getWidth();
  const margin = 10;
  let y = 10;

  // Adicionar logo

  const imgLogo = new Image();
  imgLogo.src = logo[vertical];
  pdf.addImage(imgLogo, "PNG", margin, y, 60, 30);
  y += 50;

  // Adicionar cabeçalho
  pdf.setFontSize(16);
  pdf.text("EXTRATO DA CONTA", margin, y);
  pdf.setFontSize(12);
  pdf.text(`${startDate} até ${endDate}`, pageWidth - margin, y, {
    align: "right",
  });
  y += 10;

  pdf.setDrawColor(0);
  pdf.line(margin, y, pageWidth - margin, y); // Linha horizontal
  y += 5;

  // Informações do cliente
  pdf.setFontSize(10);
  pdf.setTextColor(color[vertical]);
  pdf.text("Cliente", margin, y);
  y += 5;

  pdf.setTextColor(0);
  pdf.text(`Nome: ${name}`, margin, y);
  y += 5;

  pdf.text(`CPF: ${formatCPF(document)}`, margin, y);
  y += 5;

  pdf.text(
    `Conta: ${account.accountNumber} - ${account.accountDigit}`,
    margin,
    y,
  );
  y += 10;

  pdf.line(margin, y, pageWidth - margin, y); // Linha horizontal
  y += 5;

  autoTable(pdf, {
    startY: 110,
    head: [headers],
    body: transactions.map((item) => Object.values(item)),
    theme: "grid",
    headStyles: { fillColor: color[vertical], cellWidth: "wrap" },
    styles: {
      cellPadding: 2,
      fontSize: 10,
      valign: "middle",
    },
    columnStyles: {
      0: { cellWidth: 30 }, // Id
      1: { cellWidth: "auto" }, // Data
      2: { cellWidth: "auto" }, // Categoria
      3: { cellWidth: "auto" }, // Descrição
      4: { cellWidth: "auto" }, // Participante
      5: { cellWidth: "auto" }, // Tipo
      6: { cellWidth: "auto" }, // Valor (R$)
    },
  });

  pdf.save("Extrato.pdf");
};
