import styled, { createGlobalStyle, css } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  ${({ theme }) => css`
    body {
      font-family: ${theme.fonts.base};
      background-color: ${theme.palette.neutral.lighter};
      color: ${theme.palette.neutral.dark};
    }
  `};
`;

export const Container = styled.section`
  width: 100%;
  height: 100%;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 700;
  color: #1a1a1a;
`;

export const Description = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #666666;
  margin-top: 4px;
`;

export const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: column;
  margin-top: 16px;

  width: 100%;
  & > div {
    width: 100%;
    min-height: 71px;
  }
  & > p {
    width: 100%;
    min-height: 71px;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.palette.neutral.main};
    font-size: ${({ theme }) => theme.fontSizes.x3s};
  }
  &.flex-end {
    justify-content: space-between;
    height: 50vh;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
export const WithoutBankStatementWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
