/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState } from "react";
import { ExportRequest, ExportResponse } from "@/types";
import {
  transformCSVStatementsResponse,
  transformPDFStatementsResponse,
  transformStatementsResponse,
} from "@/utils/transform";
import { API, useAccountStore } from "@/utils/utility";
import axios from "axios";
import { format } from "date-fns";

const getApiDate = (date) => {
  return format(date, "yyyy-MM-dd");
};

const getExportData = async ({
  accountId,
  endDate,
  exportType,
  startDate,
}: ExportRequest) => {
  try {
    const params = new URLSearchParams();

    params.append("accountId", accountId);
    params.append("exportType", exportType);
    params.append("startDate", startDate);
    params.append("endDate", endDate);

    const response = await API.statements.get<ExportResponse>("/Export", {
      params,
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) throw error;

    throw new Error("different error than axios");
  }
};

export const useExport = () => {
  const { currentAccountId: accountId } = useAccountStore();
  const [loading, setLoading] = useState(false);

  const handleExecute = async (props) => {
    try {
      setLoading(true);
      const data = await getExportData({
        accountId,
        startDate: getApiDate(props.startDate),
        endDate: getApiDate(props.endDate),
        exportType: props.exportType,
      });

      if (data.PdfExport)
        return {
          success: true,
          data: transformPDFStatementsResponse(data.PdfExport),
        };
      if (data.CsvExport)
        return {
          success: true,
          data: transformCSVStatementsResponse(data.CsvExport),
        };
      if (data.OfxExport)
        return {
          success: true,
          data: transformStatementsResponse(data.OfxExport),
        };
    } catch (error) {
      return {
        success: false,
        error,
      };
    } finally {
      setLoading(false);
    }
  };

  return {
    execute: handleExecute,
    loading,
  };
};
