import { useEffect } from "react";
import { Layout } from "@/components";
import { routes, setHeader, useMediaQuery } from "@/utils";
import { Breadcrumb } from "@hyperlocal/vital2";
import { Link, useNavigate } from "react-router-dom";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import { Filter, Transactions } from "../../components";

export const Statement = () => {
  const isMobile = useMediaQuery("mobile");
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile) {
      setHeader({
        title: "Extrato",
        leftIcon: {
          name: "ArrowNoLineLeft",
          onClick: () => navigate(routes.dashboard),
        },
      });
    }
  }, [isMobile, navigate]);

  return (
    <div className="flex h-full w-full flex-1 flex-col bg-neutral-lighter mobile:!max-h-[100vh] mobile:!bg-neutral-white">
      <Layout.Root>
        <Layout.Header>
          <Breadcrumb.Root className="mobile:!hidden">
            <Breadcrumb.List>
              <Breadcrumb.ListItem>
                <Link to={routes.dashboard}>
                  <Breadcrumb.Home className="ml-0" />
                </Link>
              </Breadcrumb.ListItem>

              <Breadcrumb.ListItem>
                <Breadcrumb.Separator />
                <Breadcrumb.Anchor active>Extrato</Breadcrumb.Anchor>
              </Breadcrumb.ListItem>
            </Breadcrumb.List>
          </Breadcrumb.Root>
        </Layout.Header>
        <Layout.Title to={routes.dashboard} className="mobile:!hidden">
          Extrato
        </Layout.Title>

        <Layout.Content>
          <Layout.Column className="flex flex-col gap-inset-lg mobile:!hidden">
            <Parcel
              config={() => System.import("@hyperlocal/banking-balance")}
              mountParcel={mountRootParcel}
            />
            <Filter />
          </Layout.Column>

          <Layout.Column className="absolute inset-0 col-[2] overflow-hidden mobile:!relative mobile:!inset-auto mobile:!col-auto mobile:!flex mobile:!h-[calc(100vh-56px)] mobile:!flex-col">
            <div className="desktop:hidden">
              <Parcel
                config={() => System.import("@hyperlocal/banking-balance")}
                mountParcel={mountRootParcel}
              />
            </div>

            <Transactions />
          </Layout.Column>
        </Layout.Content>
      </Layout.Root>
    </div>
  );
};
