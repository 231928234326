import { ElementRef, Suspense, useRef, useState } from "react";
import {
  useExport,
  useInfiniteStatements,
} from "@/features/statements/services/hooks";
import {
  TransactionItemTransformed,
  TransactionMovement,
  TransactionType,
} from "@/types";
import {
  handleNormalizeDate,
  STATEMENTS_FILTER_PARAMS,
  useAccountStore,
  useMediaQuery,
} from "@/utils";
import Icon, { IconProps } from "@hyperlocal/vital-icons";
import { Button, Card, Dialog, Sheet, toast } from "@hyperlocal/vital2";
import { parse } from "date-fns";
import { ErrorBoundary } from "react-error-boundary";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import {
  downloadFile,
  downloadPDF,
  formatDateHour,
  generateOFXBlob,
  handleDownloadError,
} from "./helpers";

type FileType = "pdf" | "csv" | "ofx";

interface FileTypeOptions {
  label: string;
  icon: IconProps["name"];
  action: () => void;
  type: FileType;
}

const ExportStatements = () => {
  const [searchParams] = useSearchParams();
  const [fileType, setFileType] = useState<FileType | null>(null);
  const isMobile = useMediaQuery("mobile");
  const dialogRef = useRef<ElementRef<typeof Dialog.Close>>(null);

  const { account } = useAccountStore();

  const startDate = searchParams.get(STATEMENTS_FILTER_PARAMS.from) || "";

  const endDate = searchParams.get(STATEMENTS_FILTER_PARAMS.to) || "";

  const movementParam = searchParams.get(
    STATEMENTS_FILTER_PARAMS.movement,
  ) as TransactionMovement;

  const categoryParam = searchParams.getAll(
    STATEMENTS_FILTER_PARAMS.category,
  ) as TransactionType[];

  const { data } = useInfiniteStatements({
    movement: movementParam,
    startDate,
    endDate,
    type: categoryParam,
  });

  const getDates = () => {
    const startDateParam = searchParams.get(STATEMENTS_FILTER_PARAMS.from);

    const endDateParam = searchParams.get(STATEMENTS_FILTER_PARAMS.to);
    let startDate: Date;
    let endDate: Date;

    const datesAvailable = Object.keys(data.pages);

    const firstDay = parse(datesAvailable[0], "dd/MM/yy", new Date());

    const lastDay = parse(
      datesAvailable[datesAvailable.length - 1],
      "dd/MM/yy",
      new Date(),
    );

    if (!startDateParam || !endDateParam) {
      endDate = firstDay;
      startDate = lastDay;
    } else {
      startDate = handleNormalizeDate(startDateParam);
      endDate = handleNormalizeDate(endDateParam);
    }

    return { startDate, endDate };
  };

  const getExportData = useExport();

  const generatePdf = async (data) => {
    try {
      downloadPDF({
        vertical: "hyperlocal",
        dates: getDates(),
        account,
        transactions: data,
      });
    } catch (error) {
      console.log("🚀 ~ file: downloadDocument.ts:172 ~ error:", error);
      handleDownloadError();
    }
  };

  const generateOfx = (data: TransactionItemTransformed[]) => {
    try {
      const { endDate, startDate } = getDates();
      const blob = generateOFXBlob({
        data,
        account: {
          accountNumber: `${account.accountNumber} - ${account.accountDigit}`,
          bankId: account.branch,
        },
        endDate: endDate.toISOString(),
        startDate: startDate.toISOString(),
      });

      downloadFile({ blob, extension: "ofx" });
    } catch (error) {
      handleDownloadError();
      console.log("🚀 ~ file: downloadDocument.ts:52 ~ error:", error);
    }
  };

  const generateCsv = (data: TransactionItemTransformed[]) => {
    try {
      const headers = [
        "Criado em",
        "Id",
        "Categoria",
        "Descrição",
        "Participante",
        "Tipo",
        "Valor",
      ];
      const rows = data.map((row) => [
        formatDateHour(row.createdAt),
        row.id,
        row.type,
        row.description,
        row.participant,
        row.movement,
        row.value,
      ]);
      const csvContent = [headers, ...rows]
        .map((row) => row.join(";"))
        .join("\r\n");

      const bom = "\uFEFF";
      const blob = new Blob([bom + csvContent], {
        type: "text/csv;charset=utf-8;",
      });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "extrato.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      handleDownloadError();
      console.log("🚀 ~ file: downloadDocument.ts:52 ~ error:", error);
    }
  };

  const exportData = async (file: FileType) => {
    await getExportData
      .execute({
        endDate: getDates().endDate,
        startDate: getDates().startDate,
        exportType: file !== "ofx" ? "pdf" : "ofx",
      })
      .then(async (response?: { success: boolean; data: any; error: any }) => {
        if (!response || !response?.success) {
          handleDownloadError();
          console.log("🚀 ~ file: downloadDocument.ts:52 ~ error:", response?.error);
          return;
        }

        const { data } = response;

        if (!data) return;

        if (file === "pdf") {
          generatePdf(data);
        }

        if (file === "ofx") {
          generateOfx(data);
        }

        if (file === "csv") {
          generateCsv(data);
        }

        dialogRef.current.click();
        toast({
          title: "Exportação concluída",
          variant: "success",
        });
      });
  };

  const onFileClick = (file: FileType) => {
    setFileType(file);
    exportData(file);
  };

  const files: FileTypeOptions[] = [
    {
      label: "Excel",
      icon: "GeralDocApkDocument",
      action: () => onFileClick("csv"),
      type: "csv",
    },
    {
      label: "PDF",
      icon: "GeralDocPdf",
      action: () => onFileClick("pdf"),
      type: "pdf",
    },
    {
      label: "OFX",
      icon: "GeralDocApkDocument",
      action: () => onFileClick("ofx"),
      type: "ofx",
    },
  ];

  const handleOnTryToExport = (e) => {
    const allowedToExport = !!startDate || !!endDate;
    if (allowedToExport) return;

    e.preventDefault();

    toast({
      variant: "inform",
      title: "Período inválido",
      description: "Por favor, selecione um período para exportar o relatório.",
    });

    return;
  }

  return (
    <>
      {!isMobile && (
        <div>
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <Button.Root
                onClick={handleOnTryToExport}
                className="button-export-wildcard mb-4 flex !h-8 w-fit items-center space-x-2 border border-primary-main px-3 py-2 text-xs leading-none text-primary-main"
                variant="secondary"
              >
                <Button.Slot name="GeralShareExport" className="size-[24px]" />
                Exportar
              </Button.Root>
            </Dialog.Trigger>
            <Dialog.Content className="max-w-[370px]">
              <Dialog.Header>
                <Dialog.Title>Exportar</Dialog.Title>
                <Dialog.Close ref={dialogRef}></Dialog.Close>
              </Dialog.Header>
              Por favor, selecione uma das opções sobre esta compra:
              <div className="flex flex-col gap-2">
                {files.map((file) => {
                  const isDisabled = getExportData.loading;
                  const isLoading = isDisabled && file.type === fileType;
                  return (
                    <button
                      key={file.type}
                      onClick={() => {
                        if (isDisabled) return;
                        file.action();
                      }}
                      disabled={isDisabled}
                      className={twMerge(isDisabled && "cursor-not-allowed")}
                    >
                      <Card
                        className={twMerge(
                          "flex w-full cursor-pointer content-between items-center text-neutral-black",
                        )}
                      >
                        <div className="flex w-full gap-inset-x2s">
                          <Icon
                            name={file.icon}
                            fill="currentColor"
                            className="size-inset-lg"
                          />
                          <span>{file.label}</span>
                        </div>
                        {isLoading ? (
                          <Icon
                            name="ArrowRefreshLine"
                            fill="currentColor"
                            className="size-inset-lg animate-spin"
                          />
                        ) : (
                          <Icon
                            name="ArrowNoLineRigh"
                            fill="currentColor"
                            className="size-inset-lg"
                          />
                        )}
                      </Card>
                    </button>
                  );
                })}
              </div>
            </Dialog.Content>
          </Dialog.Root>
        </div>
      )}
      {isMobile && (
        <div>
          <Sheet.Root>
            <Sheet.Trigger asChild>
              <Button.Root
                onClick={handleOnTryToExport}
                className="!h-fit shrink-0 !p-0 !px-2 !py-2 text-xs leading-none"
                variant="link"
              >
                <Button.Slot name="GeralShareExport" className="size-6" />
                Exportar
              </Button.Root>
            </Sheet.Trigger>
            <Sheet.Content side="bottom">
              <Sheet.Header>
                <Sheet.Title>Exportar</Sheet.Title>
                <Dialog.Close ref={dialogRef}></Dialog.Close>
              </Sheet.Header>
              Por favor, selecione uma das opções sobre esta compra:
              <div className="flex flex-col gap-2">
                {files.map((file) => {
                  const isDisabled = getExportData.loading;
                  const isLoading = isDisabled && file.type === fileType;
                  return (
                    <button
                      key={file.label}
                      onClick={() => {
                        if (isDisabled) return;
                        file.action();
                      }}
                      disabled={isDisabled}
                      className={twMerge(isDisabled && "cursor-not-allowed")}
                    >
                      <Card
                        className={twMerge(
                          "flex w-full cursor-pointer content-between items-center text-neutral-black",
                        )}
                      >
                        <div className="flex w-full gap-inset-x2s">
                          <Icon
                            name={file.icon}
                            fill="currentColor"
                            className="size-inset-lg"
                          />
                          <span>{file.label}</span>
                        </div>
                        {isLoading ? (
                          <Icon
                            name="ArrowRefreshLine"
                            fill="currentColor"
                            className="size-inset-lg animate-spin"
                          />
                        ) : (
                          <Icon
                            name="ArrowNoLineRigh"
                            fill="currentColor"
                            className="size-inset-lg"
                          />
                        )}
                      </Card>
                    </button>
                  );
                })}
              </div>
            </Sheet.Content>
          </Sheet.Root>
        </div>
      )}
    </>
  );
};

export const ExportStatementsAction = () => (
  <ErrorBoundary FallbackComponent={() => <div />}>
    <Suspense>
      <ExportStatements />
    </Suspense>
  </ErrorBoundary>
);
