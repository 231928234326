import { useEffect } from "react";
import { LoadingTransaction, ScrollArea } from "@/components";
import { useInfinitePayments } from "@/features/commitments/services";
import { Payment, PaymentScheduleStatus } from "@/types/commitments";
import { getDateTitle, getDay, getShortMonth } from "@/utils";
import { formatCurrency } from "@/utils/format";
import { Card } from "@hyperlocal/vital2";
import { parse } from "date-fns";
import { useInView } from "react-intersection-observer";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";
import { CommitmentReceipt } from "../commitment-receipt";

const styles = tv({
  variants: {
    status: {
      SCHEDULED: "text-status-warning-dark",
      SCHEDULE_EXECUTED: "text-status-success-dark",
      SCHEDULE_NOT_EXECUTED: "text-status-error-dark",
      SCHEDULE_CANCELED: "text-status-error-dark",
    },
  },
});

const mapStatusLabel = (payment: Payment) => {
  const status: Record<PaymentScheduleStatus, string> = {
    SCHEDULED: "pendente",
    SCHEDULE_CANCELED: "cancelado",
    SCHEDULE_EXECUTED: "efetuado",
    SCHEDULE_NOT_EXECUTED: "não pago",
  };

  if (payment.status === "SCHEDULE_EXECUTED" && !!payment.result)
    return status.SCHEDULE_NOT_EXECUTED;

  return status[payment.status];
};

const getCorrectStatus = (payment: Payment): PaymentScheduleStatus => {
  if (payment.status === "SCHEDULE_EXECUTED" && !!payment.result)
    return "SCHEDULE_NOT_EXECUTED";

  return payment.status;
};

const ListItem = ({ payment }: { payment: Payment }) => {
  const scheduledDate = new Date(payment.scheduleDate);

  const scheduledDay = getDay(scheduledDate);

  const scheduledMonth = getShortMonth(scheduledDate).toUpperCase();

  return (
    <CommitmentReceipt payment={payment}>
      <Card className="flex w-full">
        <div className="mr-4 flex flex-col items-center">
          <span
            className={twMerge(
              "font-base text-base font-bold",
              styles({ status: getCorrectStatus(payment) }),
            )}
          >
            {scheduledDay}
          </span>
          <span
            className={twMerge(
              "font-base text-[14px]/[21px]",
              styles({ status: getCorrectStatus(payment) }),
            )}
          >
            {scheduledMonth}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-left font-base text-[14px]/[21px] text-neutral-darkest">
            Boleto {mapStatusLabel(payment)}
          </span>
          <span className="font-base text-base text-neutral-dark">
            {payment.assignor}
          </span>
        </div>
        <div className="ml-auto flex flex-col items-end">
          <span className="font-base text-[14px]/[21px] text-neutral-dark">
            À vista
          </span>
          <span className={styles({ status: getCorrectStatus(payment) })}>
            {formatCurrency(payment.amount * -1)}
          </span>
        </div>
      </Card>
    </CommitmentReceipt>
  );
};

export const CommitmentList = () => {
  const { data, fetchNextPage, hasNextPage } = useInfinitePayments();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  if (!Object.values(data.pages.items)) return null;

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <ScrollArea className="mb-inset-lg h-full px-inset-lg pb-inset-lg mobile:px-inset-md">
        {Object.entries(data.pages.items).map(([key, items]) => {
          const date = parse(key, "dd/MM/yy", new Date());
          const title = getDateTitle(date);

          return (
            <div key={key} className="mb-2">
              <h5 className="mb-4 mt-6 text-base font-bold text-neutral-darkest mobile:mb-1 mobile:px-inset-md">
                {title}
              </h5>
              <ul className="flex flex-col gap-2">
                {items.map((payment) => (
                  <ListItem key={payment.id} payment={payment} />
                ))}
              </ul>
            </div>
          );
        })}

        {hasNextPage ? (
          <LoadingTransaction ref={ref} />
        ) : (
          <span className="flex w-full justify-center text-center">
            Não há mais nada por aqui...
          </span>
        )}
      </ScrollArea>
    </div>
  );
};
