import {
  Payment,
  PaymentScheduleListRequest,
  PaymentScheduleListResponse,
} from "@/types/commitments";
import { COMMITMENT_FILTER_PARAMS } from "@/utils";
import { QUERY_KEYS } from "@/utils/react-query-keys";
import { API } from "@/utils/utility";
import {
  InfiniteData,
  useQuery,
  UseQueryOptions,
  useSuspenseInfiniteQuery,
} from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { handlePaymentResponse } from "./use-get-scheduled-payments.utils";

const getScheduledParams = (params: PaymentScheduleListRequest) => {
  const searchParams = new URLSearchParams();
  if (!params) return searchParams;

  const { from, to, limit, _page } = params;

  if (from && to) {
    searchParams.set("from", from);
    searchParams.set("to", to);
  }

  if (limit) searchParams.set("limit", limit.toString());

  if (_page) searchParams.set("page", _page.toString());

  return searchParams;
};

const getScheduledPayments = async (
  requestParams?: PaymentScheduleListRequest,
) => {
  const params = getScheduledParams(requestParams);

  const response = await API.financePayments.get<PaymentScheduleListResponse>(
    "/api/Payment/listSchedule",
    {
      params,
    },
  );

  return response.data;
};

type UsePendingProps = Omit<
  UseQueryOptions<PaymentScheduleListResponse, Error, Payment[]>,
  "queryFn" | "queryKey"
>;

export const usePaymentsPending = (config?: UsePendingProps) => {
  return useQuery({
    queryKey: QUERY_KEYS.getPaymentsSchedule(),
    queryFn: () => getScheduledPayments(),
    select: (data) => {
      const pendingList = data.items.filter(
        (item) => item.status === "SCHEDULED",
      );

      return pendingList;
    },
    ...config,
  });
};

export const useInfinitePayments = () => {
  const [params] = useSearchParams();

  const startDate = params.get(COMMITMENT_FILTER_PARAMS.from);

  const endDate = params.get(COMMITMENT_FILTER_PARAMS.to);

  const search = params.get(COMMITMENT_FILTER_PARAMS.search) || "";

  const shouldUpdateDateQueryKey = !!endDate && !!startDate;

  return useSuspenseInfiniteQuery({
    queryKey: QUERY_KEYS.getInfinitePaymentsSchedule({
      ...(shouldUpdateDateQueryKey && {
        from: startDate,
        to: endDate,
      }),
    }),
    initialPageParam: 1,
    select: (data: InfiniteData<PaymentScheduleListResponse, number>) => {
      const result = data.pages.reduce(
        (prev, cur) => [...prev, ...cur.items],
        [],
      );

      return {
        pages: handlePaymentResponse(result, search),
        pageParams: data.pageParams,
      };
    },
    queryFn: ({ pageParam }) =>
      getScheduledPayments({
        from: startDate,
        to: endDate,
        _page: pageParam,
      }),
    getNextPageParam: (page, _, lastPageParam) => {
      return !page.last ? lastPageParam + 1 : undefined;
    },
  });
};
